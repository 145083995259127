$('.js-systems-tabs-nav').slick({
  variableWidth: true,
  mobileFirst: true,
  swipeToSlide: true,
  focusOnSelect: true,
  infinite: false,
  arrows: false,
  dots: false,
  asNavFor: '.js-systems-tabs',
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        draggable: false,
        swipeToSlide: false,
        slidesToShow: 5,
      },
    },
  ],
})

$('.js-systems-tabs').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.js-systems-tabs-nav',
})

// $('.js-reviews-slider').slick({
//   arrows: true,
//   dots: true,
//   mobileFirst: true,
//   swipeToSlide: true,
//   adaptiveHeight: false,
//   prevArrow: '.reviews-slider__btn--prev',
//   nextArrow: '.reviews-slider__btn--next',
//   dotsClass: 'reviews-slider__dots',
//   responsive: [
//     {
//       breakpoint: 767,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 1023,
//       settings: {
//         slidesToShow: 1,
//         variableWidth: true,
//       },
//     },

//     {
//       breakpoint: 1279,
//       settings: {
//         slidesToShow: 3,
//         variableWidth: true,
//       },
//     },
//   ],
// })
